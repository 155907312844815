import React, {useState} from 'react'
import styled, {keyframes} from 'styled-components'
import theme from '../Styles/Theme'
import backgroundImg from '../img/challenge-photo.jpg'
import arrowImage from '../img/challenge_arrow.png'

const overlayAnimationIn = keyframes`
  0% { border-width: 0 0 0 0; }
  100% { border-width: 0 0 30rem 60rem; }
`

const borderAnimationIn = keyframes`
  0% { height: .25rem; }
  100% { height: 2rem; }
`

const OuterWrapper = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  background-color: ${theme.darkGray};
  background-image: url(${backgroundImg});
  background-position: right center;
  background-size: cover;
  overflow: hidden;
  padding: 6rem 6rem 8rem;
  position: relative;
  
  &.animate {
    &:before {
      animation: ${borderAnimationIn} 1s forwards cubic-bezier(0.680, -0.550, 0.265, 1.550);
    }
    &:after {
      animation: ${overlayAnimationIn} 1.5s forwards cubic-bezier(0.680, -0.550, 0.265, 1.550);
    }
  }
  
  &:before {
    background: ${theme.darkBlue}; 
    content: '';
    display: block;
    height: .25rem;
    opacity: .4;
    position: absolute;
    right: 0;
    top:0;
    width: 100%;
  }
  
  &:after {
    border-color: transparent transparent ${theme.darkBlue} transparent;
    border-style: solid;
    border-width: 0 0 0 0;
    bottom: 0; 
    content: '';
    opacity: .4;
    position: absolute;
    right: 0;
  }
`

const Content = styled.div`
    max-width: 50%;
    position: relative;
    z-index: 1;
    
    p {
        color: ${theme.white};
        line-height: 2;
    }
    
    a {
      color: ${theme.white};
      text-decoration: underline;
    }
`

const Header = styled.h2`
  color: ${theme.white};  
`

const arrowAnimationIn = keyframes`
  0% { opacity: 0; transform: rotate(-45deg); }
  100% { opacity: 1; trasform: rotate(0deg); }
`


const Arrow = styled.div`
  background: url(${arrowImage});
  bottom: 0;
  left: 16rem;
  height: 302px;
  opacity: 0;
  position: absolute;
  transform: translateY(65%);
  width: 243px;
  z-index :100;
  
  &.animate {
    animation: ${arrowAnimationIn} .5s forwards 3s;
  }
`

const Challenge = props => {
  const ScrollTopBuffer = 300;
  const [scrollTop, setScrollTop] = useState(ScrollTopBuffer)


  const _getScrollPosition = el => {
    if (!el || el.getBoundingClientRect().top >= ScrollTopBuffer) return

    setScrollTop(el.getBoundingClientRect().top)
  }

  return (
    <OuterWrapper>
      <Wrapper
        id='challenge'
        className={scrollTop < ScrollTopBuffer ? 'animate' : ''}
        ref={_getScrollPosition}
      >
        <Content>
          <Header>The Challenge</Header>
          <p>
            Environmental and metabolic heat limits the performance of athletes, responders, and warfighters. Suppliers
            of athletic apparel, equipment, and accessories are challenged to provide athletes with heat transfer
            technology that is highly effective, simple to produce, and fashionably appealing. Design and production
            challenges are high cost, complex structures, added weight, frigid temperature during initial use, and
            functional structure restricting performance or lacking fashion appeal. Current technology is only focused
            on body heat transfer across the core area and it ignores effective heat transfer in larger target areas of
            the body (<a target='_blank' href='https://www.youtube.com/watch?v=7nC3AMUGhW8'>Body Heat Transfer</a>).
          </p>
        </Content>
      </Wrapper>

      <Arrow className={scrollTop < ScrollTopBuffer ? 'animate' : ''}/>
    </OuterWrapper>
  )
}

export default Challenge;
