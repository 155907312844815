import React from 'react'
import styled from 'styled-components'
import theme from '../Styles/Theme'
import background from '../img/opportunity.jpg'

const Wrapper = styled.div`
  background-image: url(${background});
  background-size: cover;
  display: flex;
  min-height: 36rem;
  overflow: hidden;
  padding: 6rem;
  position: relative;
`

const Content = styled.div`
    max-width: 50%;
    position: relative;
    z-index: 1;
    
    p {
        color: ${theme.white};
        line-height: 2;
    }
`

const Header = styled.h2`
  color: ${theme.white};
`

const Opportunity = props => (
  <Wrapper id='opportunity'>
    <Content>
      <Header>Opportunity</Header>
      <p>Seeking a licensing partner for the development, design, manufacture, distribution and sale of apparel,
        equipment, and accessories using the patented All Cool platform.</p>
    </Content>
  </Wrapper>
)

export default Opportunity;
