import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import identity from 'netlify-identity-widget'

const ProtectedRoute = ({ component: Component, ...props }) => {
  useEffect(() => {
    if (!identity.currentUser()) {
      identity.open()
    }
  }, [])

  if (!identity.currentUser()) {
    return <Route render={() => <div>Please Log In</div>} />
  }

  return <Route {...props} render={renderProps => <Component {...renderProps} url={props.url} />} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authToken: PropTypes.string
}

export default withRouter(ProtectedRoute)
