import React, {useEffect, useRef, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import theme from '../Styles/Theme'

import FirefighterImage from '../img/FireFighter.jpg'
import AthleteImage from '../img/Athlete.jpg'
import HeroImage from '../img/Hero.jpg'
import HeroArrow from '../img/hero_arrow.png'

const highlightAnimation = keyframes`
  10% { transform: translateY(0); }
  30.33% { transform: translateY(0); }
  43.33% { transform: translateY(-4rem); }
  63.66% { transform: translateY(-4rem); }
  76.66% { transform: translateY(-8rem); }
  97% { transform: translateY(-8rem); } 
`

const slideAnimation = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  30.33% { opacity: 1; }
  33.33% { opacity: 0; }
  43.33% { opacity: 1; }
  63.66% { opacity: 1; }
  66.66% { opacity: 0; }
  76.66% { opacity: 1; }
  97% { opacity: 1; }
  100% { opacity: 0; } 
`

const Wrapper = styled.div`
  background: linear-gradient(${theme.black}, ${theme.darkGray});
  display: flex;
  height: 80vh;
  justify-content: flex-end;
  max-height: 46rem;
  min-height: 30rem;
  padding: 20vh 6rem;
  position: relative;
`

const Slide = styled.div`
  animation: ${slideAnimation} 9s infinite cubic-bezier(0.680, -0.550, 0.265, 1.550);
  background-size: cover;
  background-position: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const SlideContent = styled.div`
  color: ${theme.white};
  max-width: 50%;
  position: relative;
  text-align: right;
  width: 30rem;
`

const Highlight = styled.div`
  border-bottom: 2px solid ${theme.blue};
  color: ${theme.blue};
  display: inline-block;
  height: calc(4rem);
  margin-right: .5rem;
  overflow: hidden;
`

const HighlightList = styled.div`
  animation: ${highlightAnimation} 9s infinite cubic-bezier(0.680, -0.550, 0.265, 1.550);
`

const arrowAnimation = keyframes`
  0% { 
    height: 0; 
    opacity: 0;
    transform: translateY(-30px)
  }
  80% {
    height: 460px; 
    opacity: 1;
    transform: translateY(20px)
  }
  100% { 
    height: 460px; 
    opacity: 1;
    transform: translateY(0)
  } 
`

const Arrow = styled.div`
  animation: ${arrowAnimation} 1s 9s forwards ease-out;
  background-image: url(${HeroArrow});
  top: 35vh;
  height: 0;
  position: absolute;
  right: 26rem;
  width: 130px;
  z-index: 100;
`

const ImageList = [
  FirefighterImage,
  AthleteImage,
  HeroImage
]

const SlideShow = props => {
  const [index, setIndex] = useState(0);

  useInterval(() => {
    if (index + 2 > ImageList.length) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }

  }, 3000);

  return (
    <Slide style={{backgroundImage: `url(${ImageList[index]})`}}/>
  )
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const HighlightText = () => {
  return (
    <HighlightList>
      <div>responder</div>
      <div>athlete</div>
      <div>hero</div>
    </HighlightList>
  )
}

const Hero = props => (
  <Wrapper>
    <SlideShow  {...props}/>
    <SlideContent>
      <h1>Performance and recovery for the <Highlight><HighlightText/></Highlight>.</h1>
    </SlideContent>
    <Arrow />
  </Wrapper>
)

export default Hero;
