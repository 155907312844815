import React, {useState} from 'react'
import MaterialIcon from 'material-icons-react';
import styled, {keyframes} from 'styled-components'
import {rgba} from 'polished'
import theme from '../Styles/Theme'
import backgroundTexture from '../img/benefits_texture.png'
import triangleImage from '../img/shoulder-triangle.png'
import arrowImage from '../img/benefits_arrow.png'

const OuterWrapper = styled.div`
    position: relative;
`

const Wrapper = styled.div`
  justify-content: center;
  background: ${theme.blue} url(${backgroundTexture});
  display: flex;
  min-height: 46rem;
  overflow: hidden;
  padding: 6rem 6rem 8rem;
  position: relative;
`

const Content = styled.div`
    max-width: 60%;
    position: relative;
    z-index: 1;
    
    p {
        line-height: 2;
    }
`

const Header = styled.h2`
  color: ${theme.white};
`

const ListWrapper = styled.ul`
  background: ${rgba(theme.darkishBlue, .1)};
  padding: 2rem;
`

const ListItem = styled.li`
  align-items: center;
  color: ${theme.white};
  display: flex;
  font-size: 1.3rem;
  margin-bottom: .5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`

const calloutAnimationIn = keyframes`
  0% { opacity: 0; top: -1rem; }
  100% { opacity: 1; top: 0; }
`

const Callout = styled.div`
  background: ${theme.white};
  color: ${theme.darkishBlue};
  font-family: ${theme.headerFont};
  font-size: 1.6rem;
  opacity: 0;
  padding: 1rem;
  position: absolute;
  right: 3rem;
  top: -4px;
  transform: translateY(-60%);
  width: 20rem;
  
  &.animate {
    animation: ${calloutAnimationIn} 1s forwards cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
`

const IconAnimationIn = keyframes`
  0% { transform: scale(.8); }
  100% { transform: scale(1); }
`

const IconWrapper = styled.div`
  align-items: center;
  background: ${theme.darkishBlue};
  border-radius: 50%;
  display: flex;
  flex: none;
  height: 3rem;
  justify-content: center;
  margin-right: 1rem;
  transform: scale(.8);
  width: 3rem;
 
  &.animate-0 { animation: ${IconAnimationIn} .5s forwards .1s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-1 { animation: ${IconAnimationIn} .5s forwards .2s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-2 { animation: ${IconAnimationIn} .5s forwards .3s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-3 { animation: ${IconAnimationIn} .5s forwards .4s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-4 { animation: ${IconAnimationIn} .5s forwards .5s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-5 { animation: ${IconAnimationIn} .5s forwards .6s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-6 { animation: ${IconAnimationIn} .5s forwards .7s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-7 { animation: ${IconAnimationIn} .5s forwards .8s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  &.animate-8 { animation: ${IconAnimationIn} .5s forwards .9s cubic-bezier(0.680, -0.550, 0.265, 1.550); }
  
  .material-icons {
    font-size: 30px !important;
    transform: translateY(3px);
  }
`

const SnowboarderImage = styled.div`
  background-image: url(${triangleImage});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  height: 90%;
  position: absolute;
  right: 0;
  width: 75%;
`

const arrowAnimationIn = keyframes`
  0% { opacity: 0; transform: rotate(45deg); }
  100% { opacity: 1; trasform: rotate(0deg); }
`

const Arrow = styled.div`
  background: url(${arrowImage});
  bottom: 0;
  left: 5rem;
  height: 307px;
  opacity: 0;
  position: absolute;
  transform: translateY(30%);
  width: 201px;
  z-index :100;
  
  &.animate {
    animation: ${arrowAnimationIn} .5s forwards 3s;
  }
`

const Benefits = props => {
  const ScrollTopBuffer = 600;
  const [scrollTop, setScrollTop] = useState(ScrollTopBuffer)
  const iconList = [
    {label: 'Low Cost High Value Innovation Acquisition', icon: 'favorite'},
    {label: 'Commercially Available in 2019', icon: 'done_all'},
    {label: 'Blocks competitive innovation', icon: 'pan_tool'},
    {label: 'Improved athletic performance', icon: 'directions_run'},
    {label: 'Delayed fatigue and heat exhaustion', icon: 'directions_bike'},
    {label: 'Decreased dehydration', icon: 'local_drink'},
    {label: 'Accelerated musculoskeletal trauma recovery', icon: 'local_hospital'},
    {label: 'No power or refrigeration required', icon: 'flash_off'},
    {
      label: 'Works in any environment—regardless of temperature, humidity, or solar-heating',
      icon: 'power_settings_new'
    },
  ]

  const _getScrollPosition = el => {
    if (!el || el.getBoundingClientRect().top >= ScrollTopBuffer) return

    setScrollTop(el.getBoundingClientRect().top)
  }

  return (
    <OuterWrapper id='benefits'>
      <Wrapper>
        <Content>
          <Header>Benefits</Header>
          <ListWrapper>
            {iconList.map(({label, icon}, index) => (
              <ListItem key={icon}>
                <IconWrapper className={scrollTop < ScrollTopBuffer ? `animate-${index}` : ''}>
                  <MaterialIcon icon={icon} color='white'/>
                </IconWrapper>
                {label}
              </ListItem>
            ))}
          </ListWrapper>
        </Content>
        <SnowboarderImage/>
      </Wrapper>
      <Arrow className={scrollTop < ScrollTopBuffer ? 'animate' : ''}/>
      <Callout
        className={scrollTop < ScrollTopBuffer ? 'animate' : ''}
        ref={_getScrollPosition}
      >
        All Cool provides the only technology that solves the design and production challenges associated with
        “cooling” garments currently on the market.
      </Callout>
    </OuterWrapper>
  )
}

export default Benefits;
