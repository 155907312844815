import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled, { ThemeProvider } from 'styled-components'
import theme from './Styles/Theme'
import BaseStyles from './Styles/BaseStyles.js'

import Header from './shared-components/Header'
import Footer from './shared-components/Footer'

import Hero from './Landing/Hero'
import Challenge from './Landing/Challenge'
import Solution from './Landing/Solution'
import Benefits from './Landing/Benefits'
import Opportunity from './Landing/Opportunity'

const AppWrapper = styled.div`
  background: ${theme.white};
  margin: 0 auto;
  max-width: 1366px;
  min-height: 100vh;
  min-width: 1024px;
`

const App = props => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', _.throttle(_handleScroll, 500))
    window.mixpanel.track('Landing Page Viewed')
  }, [])

  const _handleScroll = () => {
    setScrollTop(window.pageYOffset)
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <BaseStyles />
        <AppWrapper>
          <Header scrollTop={scrollTop} />
          <main>
            <Hero />
            <Challenge />
            <Solution />
            <Benefits />
            <Opportunity />
          </main>
          <Footer />
        </AppWrapper>
      </React.Fragment>
    </ThemeProvider>
  )
}

export default App
