export default {
  black: '#000000',
  darkGray: '#222222',
  gray: '#555555',
  red: '#dc3552',
  blue: '#55b5e6',
  green: '#3aa23c',
  darkBlue: '#11232d',
  darkishBlue: '#09507f',
  white: '#ffffff',
  offWhite: '#f1f2f2',
  headerFont: 'hwt-artz, sans-serif',
  bodyFont: 'proxima-nova, sans-serif',
  weightBold: '700',
  weightRegular: '400',
}