import React, {useState} from 'react'
import styled, {keyframes} from 'styled-components'
import theme from '../Styles/Theme'
import backgroundTexture from '../img/solution_texture.png'
import drawings from '../img/drawings_real.png'
import arrowImage from '../img/solution_arrow.png'

const overlayAnimationIn = keyframes`
  0% { border-width: 0 0 0 0; }
  100% { border-width: 0 90rem 40rem 0; }
`

const OuterWrapper = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  justify-content: flex-end;
  background: ${theme.offWhite} url(${backgroundTexture});
  display: flex;
  min-height: 46rem;
  overflow: hidden;
  padding: 6rem;
  position: relative;
  
  &.animate:before {
    animation: ${overlayAnimationIn} 1.5s forwards cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
  
  &:before {
    border-color: transparent ${theme.green} transparent transparent;
    border-style: solid;
    border-width: 0 0 0 0;
    top: 0; 
    content: '';
    opacity: .2;
    position: absolute;
    right: 0;
  }
  
  &:after {
    background-image: url(${drawings});
    background-position: bottom left;
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    height: 807px;
    left: 0;
    position: absolute;
    width: 795px;
  }
`

const Content = styled.div`
    max-width: 60%;
    position: relative;
    z-index: 1;
    
    p {
        line-height: 2;
    }
`

const arrowAnimationIn = keyframes`
  0% { opacity: 0; transform: rotate(45deg); }
  100% { opacity: 1; trasform: rotate(0deg); }
`


const Arrow = styled.div`
  background: url(${arrowImage});
  bottom: 0;
  right: 30rem;
  height: 290px;
  opacity: 0;
  position: absolute;
  transform: translateY(55%);
  width: 245px;
  z-index :100;
  
  &.animate {
    animation: ${arrowAnimationIn} .5s forwards 3s;
  }
`

const Header = styled.h2``

const Challenge = props => {
  const ScrollTopBuffer = 500;
  const [scrollTop, setScrollTop] = useState(ScrollTopBuffer)

  const _getScrollPosition = el => {
    if (!el || el.getBoundingClientRect().top >= ScrollTopBuffer) return

    setScrollTop(el.getBoundingClientRect().top)
  }

  return (
    <OuterWrapper>
      <Wrapper
        id='solution'
        className={scrollTop < ScrollTopBuffer ? 'animate' : ''}
        ref={_getScrollPosition}
      >
        <Content>
          <Header>The Solution</Header>
          <p>
            Transfer body heat arising from the core and all target areas simultaneously while limiting dehydration.
            All Cool removes heat from the body while minimizing sweat loss. All Cool does this by addressing
            differential body heat transfer from more than 90 percent of the body by transferring core and target
            area body heat into the environment, regardless of temperature, humidity, or solar-heating levels. Adopt
            All Cool’s patented technology to ensure athletes’ performance-generated body heat from nearly all areas
            of their body is effortlessly converted to cooling energy.
          </p>
        </Content>
      </Wrapper>

      <Arrow className={scrollTop < ScrollTopBuffer ? 'animate' : ''}/>
    </OuterWrapper>
  )
}

export default Challenge;
