import React, { useEffect } from 'react'
import styled from 'styled-components'
import theme from '../Styles/Theme'
import BaseStyles from '../Styles/BaseStyles.js'

import Header from '../shared-components/Header'
import Footer from '../shared-components/Footer'
import _ from "lodash";

const AppWrapper = styled.div`
  background: ${theme.white};
  margin: 0 auto;
  max-width: 1366px;
  min-height: 100vh;
  min-width: 1024px;
`

const IframeWrapper = styled.div`
  padding-top: 60%;
  position: relative;
  
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const Presentation = props => {
  useEffect(() => {
    window.mixpanel.track(`Presentation Viewed (${props.location.pathname})`)
  }, [])

  return (
    <React.Fragment>
      <BaseStyles />

      <AppWrapper>
        <Header condensed={true} fixed={false} showNav={false}/>
        <IframeWrapper>
          <iframe width="550" height="400" src={props.url} title={props.url} allowFullScreen="1"/>
        </IframeWrapper>
        <Footer/>
      </AppWrapper>
    </React.Fragment>
  )
}

export default Presentation;
