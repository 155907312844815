import React from 'react'
import styled from 'styled-components'
import theme from '../Styles/Theme'
import LogoImage from "../img/header_logo.svg";
import {rgba} from "polished";

const backgroundColor = (props) => {
  switch (true) {
    case (props.condensed):
      return rgba(theme.darkBlue, .8)
    case (!props.fixed):
      return theme.black
    default:
      return 'transparent'
  }
}

const Wrapper = styled.header`
  align-items: center;
  background: ${props => backgroundColor(props)};
  display: flex;
  justify-content: space-between;
  max-width: 1366px;
  padding: ${props => props.condensed ? '1rem 6rem' : '2rem 6rem 0'};
  position: ${props => props.fixed ? 'fixed' : 'relative'};
  transition: all .25s;
  width: 100%;
  z-index: 1000;
`

const NavLink = styled.a`
  color: ${theme.white};
  opacity: .6;
  text-transform: uppercase;
  transition: opacity .25s;
  
  &:hover {
    opacity: 1;
  }
`

const Separator = styled.span`
  color: ${props => props.darkMode ? theme.darkGray : theme.white};
  margin: ${props => props.darkMode ? '1rem' : '2rem'};
  opacity: .6;
`

const Header = props => {
  return (
    <Wrapper className="App-header" fixed={props.fixed} condensed={props.scrollTop > 100 || props.condensed}>
      <a href='/'><img src={LogoImage} alt='all cool logo'/></a>

      {props.showNav && (
        <nav>
          <NavLink href='#challenge'>Challenge</NavLink>
          <Separator>|</Separator>
          <NavLink href='#solution'>Solution</NavLink>
          <Separator>|</Separator>
          <NavLink href='#benefits'>Benefit</NavLink>
          <Separator>|</Separator>
          <NavLink href='#opportunity'>Opportunity</NavLink>
        </nav>
      )}
    </Wrapper>
  )
}

Header.defaultProps = {
  condensed: false,
  darkMode: false,
  fixed: true,
  showNav: true,
  scrollTop: 0,
}

export default Header;
