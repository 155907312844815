import React from 'react'
import styled from 'styled-components'
import theme from '../Styles/Theme'
import FooterLogo from "../img/footer_logo.svg";

const Wrapper = styled.footer`
  align-items: center;
  background: ${theme.white};
  display: flex;
  justify-content: space-between;
  padding: 2rem 6rem;
  
  a {
    color: ${theme.blue};
  }
`

const Separator = styled.span`
  color: ${props => props.darkMode ? theme.darkGray : theme.white};
  margin: ${props => props.darkMode ? '1rem' : '2rem'};
  opacity: .6;
`

const Footer = props => (
  <Wrapper>
    <img src={FooterLogo} alt='all cool logo'/>
    <div>
      <span>&copy;{ new Date().getFullYear() } allcoolusa.com</span>
      <Separator darkMode={true}>|</Separator>
      <a href='mailto:dave@allcoolusa.com'>dave@allcoolusa.com</a>
      <Separator darkMode={true}>|</Separator>
      <a href='callto:8007900368'>800.790.0368</a>
    </div>
  </Wrapper>
)

export default Footer;
