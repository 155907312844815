import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import App from '../App'
import Presentation from './Presentation'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <ProtectedRoute exact path="/presentation/intro" url='https://onedrive.live.com/embed?cid=4A1F0A68631CFBF6&amp;resid=4A1F0A68631CFBF6%21117&amp;authkey=ALmLzpDzqNlLv0Y&amp;em=2&amp;wdAr=1.7777777777777777' component={Presentation} />
        <ProtectedRoute exact path="/presentation/in-depth" url=' https://onedrive.live.com/embed?cid=4A1F0A68631CFBF6&amp;resid=4A1F0A68631CFBF6%21120&amp;authkey=AATSiUY7Wei5b1I&amp;em=2&amp;wdAr=1.7777777777777777' component={Presentation} />
      </Switch>
    </Router>
  )
}

export default Routes
