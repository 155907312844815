import { createGlobalStyle } from 'styled-components'
import theme from './Theme'
import reset from './reset'

export default createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }
  
  html {
    font-size: 18px;
  }

  body {
    background: linear-gradient(${theme.darkGray}, ${theme.gray});
    color: ${theme.gray};
    font-family: ${theme.bodyFont};
  }
  
  h1 {
    font-family: ${theme.headerFont};
    font-size: 3.75rem;
    letter-spacing: 2px;
    line-height: 1.1;
  }
  
  h2 {
    font-family: ${theme.headerFont};
    font-size: 2.75rem;
    letter-spacing: 3px;
    margin-bottom: 2rem;
  }
  
  a {
    text-decoration: none;
  }
`